import "core-js/modules/es.array.push.js";
//import FilterSelect from '@/components/statistics/Revenue/FilterSelect.vue'
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js';
import { mapState, mapGetters } from 'vuex';
export default {
  components: {
    //FilterSelect,
  },
  created() {
    this.documentTypes();
    this.documentGroups();
    this.offerGraph();
    this.generateYearsOptions();
    this.loadData();
  },
  beforeMount() {
    this.loadData();
    this.offerGraph();
    this.documentTypes();
    this.documentGroups();
  },
  mounted() {
    this.documentTypes();
    this.loadData();
    this.offerGraph();
  },
  computed: {
    ...mapState({
      //filtersActive: state => state.crmFilter.filterActivated,
      articleTypes: state => state.articleFilter.articleTypes,
      articleCategories: state => state.articleFilter.articleCategories,
      articleGroups: state => state.articleFilter.articleGroups,
      statisticGroups: state => state.articleFilter.statisticGroups,
      statisticCodes: state => state.articleFilter.statisticCodes,
      commissionGroups: state => state.articleFilter.commissionGroups,
      revenueGroups: state => state.articleFilter.revenueGroups,
      objectTypes: state => state.articleFilter.objectTypes,
      objectTypeGroups: state => state.articleFilter.objectTypeGroups
      //filter: state => state.articleFilter.filter,
      //visibleTabs: state => state.articlePermissions.visibleTabs
      //foundArticles: state => state.articleFilter.foundArticles
    }),
    ...mapGetters(['getFlter'])
  },
  data: function () {
    return {
      headLineText: 'Gesamtentwicklung der Dokumentarten',
      headlineTextUmsatzDb: 'Umsatz / DB: ',
      headlineTextJahresver: 'Jahresvergleich Dokumentgruppen: ',
      headlineTextDokumentanz: 'Dokumentanzahl: ',
      yearStartSelection: new Date().getFullYear() - 4,
      yearEndSelection: new Date().getFullYear(),
      CustomerDetailsYear: new Date().getFullYear() - 1,
      DB: [],
      UM: [],
      tDB: [],
      tUM: [],
      Amount: [],
      offerAll: [],
      offerOpen: [],
      offerDB: [],
      offerUM: [],
      art_kategorie: '',
      art_type: '',
      art_gruppe: '',
      statistic_gruppe: '',
      statistic_code: '',
      erloes_gruppe: '',
      prov_gruppe: '',
      objekt_typ: '',
      objekt_typ_gruppe: '',
      documentGroup: [],
      documentGroupID: [],
      selectedDocumentGroup: '40',
      filterArticle: [],
      filterArticleName: [],
      offerGraph2Series: {},
      offerGraphSeries: {},
      isCollapsed: false,
      documentTypesOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              return formatter.format(value).replace(/\s/g, '');
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      offerGraphOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      offerGraph2Options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              return formatter.format(value).replace(/\s/g, '');
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      }
    };
  },
  methods: {
    getDocumentGroupName(selectedGroupValue) {
      const selectedGroup = this.documentGroup.find(group => group.form_gruppe === selectedGroupValue);
      return selectedGroup ? selectedGroup.form_bezeichnung : '';
    },
    toggleFilters() {
      this.isCollapsed = !this.isCollapsed;
    },
    articleClose(index) {
      this.filterArticle.splice(index, 1);
      this.filterArticleName.splice(index, 1);
      this.documentTypes();
      this.offerGraph();
    },
    startdocumentTypesAndofferGraphs() {
      this.documentTypes();
      this.offerGraph();
    },
    generateYearsOptions() {
      let currentYear = new Date().getFullYear();
      let options = [];
      for (let i = currentYear; i >= 2010; i--) {
        options.push({
          text: i.toString(),
          value: i
        });
      }
      return options;
    },
    documentTypes() {
      console.log(this.selectedDocumentGroup);
      console.log(this.statistic_gruppe);
      customerDevelopmentService.getDocumentsTypes(this.yearStartSelection, this.yearEndSelection, this.min_netto, this.max_netto, this.minBetragSelection, this.maxBetragSelection, this.selectedDocumentGroup, this.filterArticle, this.statistic_gruppe, this.statistic_code, this.prov_gruppe, this.erloes_gruppe, this.objekt_typ, this.objekt_typ_gruppe).then(response => {
        console.log(response.data);
        response.data.forEach(data => {
          this.DB.push(data.db);
          this.UM.push(data.um);
          this.Amount.push(data.amount);
        });
        this.updateChart();
      }).catch(error => {
        console.error(error);
      });
    },
    updateChart() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.documentTypesOptions.xaxis.categories = xAxisCategories;
      this.$refs.totalChart.updateSeries([{
        name: 'Deckungsbetrag',
        data: this.DB
      }, {
        name: 'Umsatzsumme',
        data: this.UM
      }
      /*{
        name: 'Anzahl',
        data: this.Amount
      }*/]), this.$refs.totalChart.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.DB = [];
      this.UM = [];
      this.Amount = [];
    },
    yearBack() {
      let yearStart = this.yearStartSelection;
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1);
        }
        this.yearStartSelection = yearStart - 1;
        this.yearEndSelection -= 1;
        this.documentTypes();
        this.offerGraph();
      }
    },
    yearForward() {
      let yearEnd = this.yearEndSelection;
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1);
        }
        this.yearStartSelection += 1;
        this.yearEndSelection = yearEnd + 1;
        this.documentTypes();
        this.offerGraph();
      }
    },
    loadData() {
      this.$store.dispatch('loadArticleTypes');
      this.$store.dispatch('loadArticleCategories');
      this.$store.dispatch('loadArticleGroups');
      this.$store.dispatch('loadStatisticGroups');
      this.$store.dispatch('loadStatisticCodes');
      this.$store.dispatch('loadCommissionGroups');
      this.$store.dispatch('loadRevenueGroups');
      this.$store.dispatch('loadObjectTypes');
      this.$store.dispatch('loadObjectTypeGroups');
      //this.$store.dispatch('loadVisibleTabs')

      const storedFilter = this.$store.getters.getFlter;
      this.articleType = storedFilter.articleType;
      this.articleCategory = storedFilter.articleCategory;
      this.articleGroup = storedFilter.articleGroup;
      this.statisticGroup = storedFilter.statisticGroup;
      this.statisticCode = storedFilter.statisticCode;
      this.commissionGroup = storedFilter.commissionGroup;
      this.revenueGroup = storedFilter.revenueGroup;
      this.objectType = storedFilter.objectType;
      this.objectTypeGroup = storedFilter.objectTypeGroup;
    },
    addArticle() {
      if ((this.art_kategorie == '' || this.art_kategorie == null) && (this.art_type == '' || this.art_type == null) && (this.art_gruppe == '' || this.art_gruppe == null) && (this.statistic_gruppe == '' || this.statistic_gruppe == null) && (this.statistic_code == '' || this.statistic_code == null) && (this.erloes_gruppe == '' || this.erloes_gruppe == null) && (this.prov_gruppe == '' || this.prov_gruppe == null) && (this.objekt_typ == '' || this.objekt_typ == null) && (this.objekt_typ_gruppe == '' || this.objekt_typ_gruppe == null)) {
        return;
      }
      let artkat = '';
      let arttyp = '';
      let artgrp = '';
      let artkatn = '';
      let arttypn = '';
      let artgrpn = '';
      if (this.art_kategorie != '' && this.art_kategorie != null) {
        artkat = this.art_kategorie.key.lfdnr;
        artkatn = this.art_kategorie.description;
      }
      if (this.art_type != '' && this.art_type != null) {
        arttyp = this.art_type.key.tpCode;
        arttypn = this.art_type.description;
      }
      if (this.art_gruppe != '' && this.art_gruppe != null) {
        artgrp = this.art_gruppe.key.lfdnr;
        artgrpn = this.art_gruppe.description;
      }
      this.filterArticle.push({
        art_kategorie: artkat,
        art_type: arttyp,
        art_gruppe: artgrp
      }), this.filterArticleName.push({
        art_kategorie: artkatn,
        art_type: arttypn,
        art_gruppe: artgrpn
      });
      this.art_statisticCode = '';
      this.art_commissionGroup = '';
      this.art_revenueGroup = '';
      this.art_objectType = '';
      this.art_objectTypeGroup = '';
      this.documentTypes();
      this.offerGraph();
    },
    documentGroups() {
      customerDevelopmentService.getDocumentsGroups().then(response => {
        console.log('groups');
        console.log(response.data);
        response.data.forEach(data => {
          //this.documentGroupID.push(data.form_gruppe)
          this.documentGroup.push(data);
        });
        console.log('test');
        console.log(this.documentGroup);
      }).catch(error => {
        console.error(error);
      });
    },
    articleClose2(index) {
      this.filterArticle.splice(index, 1);
      this.filterArticleName.splice(index, 1);
      this.offerGraph();
    },
    offerGraph() {
      customerDevelopmentService.getOffers(this.yearStartSelection, this.yearEndSelection, this.min_netto, this.max_netto, this.minBetragSelection, this.maxBetragSelection, this.filterArticle, this.selectedDocumentGroup, this.statistic_gruppe, this.statistic_code, this.prov_gruppe, this.erloes_gruppe, this.objekt_typ, this.objekt_typ_gruppe).then(response => {
        response.data.forEach(data => {
          this.offerAll.push(data.tCount);
          this.offerOpen.push(data.oCount);
          this.offerDB.push(data.oDb);
          this.offerUM.push(data.oUm);
          this.tDB.push(data.tDb);
          this.tUM.push(data.tUm);
        });
        this.updateChart1();
        this.updateChart2();
      }).catch(error => {
        console.error(error);
      });
    },
    updateChart1() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.offerGraphOptions.xaxis.categories = xAxisCategories;
      this.$refs.totalChart1.updateSeries([{
        name: 'Anzahl',
        data: this.offerAll
      }, {
        name: 'Offen',
        data: this.offerOpen
      }]), this.$refs.totalChart1.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.offerAll = [];
      this.offerOpen = [];
    },
    updateChart2() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.$refs.totalChart2.updateSeries([{
        name: 'Offener Deckungsbetrag',
        group: 'Gesamt',
        data: this.offerDB
      }, {
        name: 'Offener Umsatz',
        data: this.offerUM
      }, {
        name: 'Gesamter Deckungsbetrag',
        data: this.tDB
      }, {
        name: 'Gesamter Umsatz',
        data: this.tUM
      }]), this.$refs.totalChart2.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.tDB = [];
      this.tUM = [];
      this.offerDB = [];
      this.offerUM = [];
    }
  }
};