import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import addressService from '@/services/crm/addressService.js';
import distributorService from '@/services/statistics/distributorService.js';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    articleTypes: [],
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [{
      text: 'Bezeichnung',
      align: 'start',
      value: 'description'
    }, {
      text: 'Anfangsbestand (€)',
      value: 'openingValue',
      align: 'end'
    }, {
      text: 'Einkäufe (€)',
      value: 'purchasesValue',
      align: 'end'
    }, {
      text: 'Verkäufe (€)',
      value: 'salesValue',
      align: 'end'
    }, {
      text: 'Inventur (€)',
      value: 'inventoryValue',
      align: 'end'
    }, {
      text: 'Materialverbrauch (€)',
      value: 'materialsInputValue',
      align: 'end'
    }, {
      text: 'Endbestand (€)',
      value: 'finalValue',
      align: 'end'
    }],
    // Chart
    series: [{
      name: 'Euro (€)',
      data: []
    }],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top' // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },
      xaxis: {
        categories: [],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      title: {
        text: 'Gesamtstatistik',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      },
      colors: ['#FF6633']
    }
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
      });
      this.loadArticleTypeStatistics();
    },
    loadArticleTypeStatistics() {
      console.log('loadArticeleadsfasdfasdfasdfasdfasdf');
      this.loading = true;
      distributorService.getArticleTypesStatistics(this.lfdnr, this.filterFromDate, this.filterToDate).then(response => {
        this.articleTypes = response.data;
        this.loading = false;
      });
      distributorService.getArticleTypesSummaryStatistics(this.lfdnr, this.filterFromDate, this.filterToDate).then(response => {
        var obj = response.data;
        var chartData = [obj.openingValue, obj.purchasesValue, obj.salesValue, obj.inventoryValue, obj.materialsInputValue, obj.finalValue];
        this.updateChart(chartData);
      });
    },
    openArticleType(item) {
      console.log('test');
      // statisticsDistributorArticleCategories
      this.$router.push({
        name: 'statisticsDistributorArticleCategories',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: {
          lfdnr: this.lfdnr,
          articleType: item.code
        }
      });
    },
    updateChart(chartData) {
      var chartHeaders = [];
      this.headers.forEach(item => {
        if (item.text.includes('€')) chartHeaders.push(item.text);
      });
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: chartHeaders
          },
          title: {
            text: this.address.name + ' - Gesamtstatistik'
          }
        }
      };
      this.$refs.summaryChart.updateSeries([{
        data: chartData
      }], false, true);
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    })
  },
  mounted() {
    this.init();
  },
  watch: {
    filterFromDate: function () {
      this.loadArticleTypeStatistics();
    },
    filterToDate: function () {
      this.loadArticleTypeStatistics();
    }
  }
};